.root {
  width: 150px;
}

.root ul {
  padding: 0;
  margin-top: 1rem;
  border-bottom: 1px solid #ccc;
  margin-bottom: 1rem;
}

.root ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  padding-bottom: 0.75rem;
}

.root ul:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.root ul:last-child li:last-child {
  padding-bottom: 0;
}

.delete {
  color: red;
}
