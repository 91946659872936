.root {
  position: absolute;
  top: 0;
  color: #fff;
  width: 100%;
  display: inline-block;
  z-index: 3;
}

.switchAnimation {
  z-index: 99 !important;
}

.switchAnimation > :global(div) > :global(div) {
  animation-name: placementHover;
  animation-duration: 1s;
  transition-delay: 2s;
  animation-fill-mode: forwards;
}

@keyframes placementHover {
  to {
    transform: scale3d(1.05, 1.05, 1);
    box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.03),
      0 2px 5px rgba(50, 50, 93, 0.1), 0 1px 2px rgba(50, 50, 93, 0.08);
  }
}
