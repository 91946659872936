.root {
  margin-left: 1rem;
  height: 100%;
}

.iframe {
  border: 1px solid #ddd;
  width: 100%;
  height: 750px;
}
