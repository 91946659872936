.root {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin: 0;
  line-height: 40px;
  text-align: center;
  display: inline-block;
}

.root img {
  width: 100%;
  border-radius: 40px;
}
