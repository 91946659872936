.line-items__title,
.line-items__title:nth-child(odd) {
  font-weight: bold;
  background-color: #edf6f9;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}
.line-items__title:hover {
  background-color: #e2f3f9;
}
