.rc-time-picker {
  display: block;
  /* width: 50%; */
  font-family: 'Lato', sans-serif;
}

.rc-time-picker-panel {
  position: fixed;
}

.form__row--inline .rc-time-picker {
  margin-right: 0.75rem;
}
.rc-time-picker input {
  font-family: inherit;
  font-size: 0.85rem;
  padding: 0.4rem 0.75rem;
  line-height: 1.5;
  border-image: none;
  border-style: solid;
  border-width: 2px;
  border-color: #ddd;
  border-radius: 0.2rem;
  background-clip: padding-box;
  display: block;
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
  height: auto;
  outline: 0;
}

.rc-time-picker-panel-narrow {
  /* max-width: 20%;
    width: 20%; */
}

.rc-time-picker-panel-input {
  font-size: 0.85rem;
  font-family: inherit;
}

.rc-time-picker-panel-input-wrap {
  padding: 0.4rem 0.75rem;
  font-family: 'Lato', sans-serif;
}

.rc-time-picker-panel-inner {
  font-size: 0.85rem;
  border: 2px solid #ccc;
}

.rc-time-picker-panel-select {
  font-size: 0.85rem;
  width: 55px;
  font-family: 'Lato', sans-serif;
}

.rc-time-picker input:focus {
  outline: 0;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-color: #fff;
}
.rc-time-picker input::placeholder {
  color: #999;
}
.rc-time-picker {
  /* font-size: 0.8rem; */
}

.rc-time-picker-clear {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  top: 3px;
  margin: 0;
}

.rc-time-picker-clear-icon:after {
  content: '⨯';
  font-size: 25px;
}
