.columnsRoot {
  display: flex;
  overflow-x: scroll;
  min-height: 800px;
}

.columnsContainer {
  padding: 1rem 0 1rem 1rem;
  border: 1px solid #ccc;
  min-height: 800px;
}
