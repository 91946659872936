.text-button {
  border: 0;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  color: #399ba2;
  cursor: pointer;
  line-height: 1;
  vertical-align: baseline;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Lato', sans-serif;
}
.text-button__icon {
  flex: 0 0 auto;
}
.text-button__text {
  flex: 1 1 auto;
}
.text-button:visited {
  color: #399ba2;
}
.text-button:hover {
  color: #143538;
}
.text-button:active {
  outline: 0;
}
