.ticket-form-inventory {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.ticket-form-inventory-section {
  flex: 1 0 auto;
  max-width: 50%;
}

.ticket-form-pricing {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.ticket-form-pricing-section {
  flex: 1 0 auto;
  max-width: 33%;
}

.ticket-form-pricing-section:last-child {
  flex: 2 0 auto;
  min-width: 60%;
  max-width: 60%;
  width: 60%;
}

.ticket-form-pricing-section--calculator {
  background-color: #deeff7;
  padding: 1rem;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 0 1rem 0;
}

/* .ticket-form-pricing .form__row {
    width: 90%;
    margin-right: 5%;
  } */

@media only screen and (max-width: 699px) {
  .ticket-form-pricing,
  .ticket-form-inventory {
    flex-direction: column;
  }
  .ticket-form-pricing-section,
  .ticket-form-inventory-section {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .ticket-form-pricing-section:last-child {
    width: 90%;
    min-width: 90%;
    max-width: 90%;
  }
}
