.root {
  cursor: pointer;
  height: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
}

.content {
  padding: 0.5rem;
  background-color: #399ba2;
  border-radius: 0.2rem;
  font-size: 0.75rem;
  box-sizing: border-box;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.laneTicketReceiptPlacementName {
  font-weight: 700;
}

.laneTicketReceiptNotesIcon {
  padding-top: 0.25rem;
}
