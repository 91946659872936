.embed-code {
  padding: 1rem;
  background-color: #edf6f9;
}
.embed-code__title {
  display: block;
  padding: 0;
  margin: 1rem 0;
  font-size: 1rem;
  font-weight: 400;
}
.embed-code__text {
  font-family: 'lato', sans serif;
  font-size: 0.85rem;
  padding: 0.5rem;
  border-image: none;
  border-style: solid;
  border-width: 0.09rem;
  border-color: #ddd;
  border-radius: 0.2rem;
  box-shadow: none;
  background-clip: padding-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  resize: none;
}
