.loginImageWrapper {
  position: absolute;
  bottom: 0;
  right: 4%;
  width: 23%;
  padding: 0;
  line-height: 0;
}

.loginImage {
  width: 100%;
}
