.notEnoughSpaceWarningContainer {
  position: absolute;
  background-color: rgba(255, 0, 0, 0.4);
  width: 100%;
  z-index: 2;
  padding: 2px;
  box-sizing: border-box;
}

.notEnoughSpaceWarningLabel {
  position: absolute;
  color: #fff;
  background: rgb(138, 40, 40);
  font-size: 12px;
  line-height: 1.2;
  bottom: 5px;
  left: 5px;
  width: 60px;
  padding: 5px;
  border-radius: 0.2rem;
}
