.searchbar {
  display: inline-block;
  position: relative;
  width: 100%;
  white-space: nowrap;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  position: relative;
}

.searchbar__icon {
  color: #9faab2;
  font-weight: lighter;
  font-size: 0.9rem;
  position: absolute;
  left: 0.6rem;
  top: 50%;
  line-height: normal;
  transform: translateY(-50%);
}

.searchbar-input {
  padding: 0.6rem 1rem;
  border-radius: 0.2rem;
  font-size: 0.9rem;
  background-color: #ffffff;
  border: solid 1px #ddd;
  background: transparent;
  border: none;
  display: inline-block;
  transition: box-shadow 0.4s ease, background 0.4s ease;
  border: 1px solid #d4d8e3;
  box-shadow: none;
  transition: box-shadow 0.3s, border 0.3s;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  appearance: none;
  font-family: inherit;
  margin: 0;
}

.searchbar-input::-webkit-search-decoration,
.searchbar-input::-webkit-search-cancel-button,
.searchbar-input::-webkit-search-results-button,
.searchbar-input::-webkit-search-results-decoration {
  display: none;
}
.searchbar-input:hover,
.searchbar-input:active,
.searchbar-input:focus {
  box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.02),
    0 2px 5px rgba(50, 50, 93, 0.2), 0 1px 2px rgba(50, 50, 93, 0.06);
  outline: 0;
}

.searchbar-input::placeholder {
  color: #9faab2;
}
