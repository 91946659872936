.country-select__wrap {
  padding: 0.5rem;
  clear: both;
  position: relative;
  border-image: none;
  border-style: solid;
  border-width: 2px;
  border-color: #ddd;
  border-radius: 3px;
  box-shadow: none;
  background-clip: padding-box;
  box-sizing: border-box;
  margin: 0.75rem 0 0 0;
  background: #fff;
}

.country-select__wrap select {
  font-family: 'lato', sans serif;
  font-size: 0.85rem;
  width: 100%;
  border-style: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  margin: 0 -15px 0 -30px;
  padding: 0 15px 0 30px;
  display: inline;
}

.country-select__wrap::after {
  content: '';
  position: absolute;
  width: 9px;
  height: 5px;
  right: 20px;
  top: 50%;
  margin-top: -2px;
  background-image: url(./dropdown.svg);
  pointer-events: none;
}

.country-select__wrap::before {
  display: inline-block;
  content: '';
  width: 21px;
  height: 15px;
  background: url(./flags.svg);
  background-position: -1000px -1000px;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.country-select__wrap.at::before {
  background-position: -165px -10px;
}
.country-select__wrap.au::before {
  background-position: -196px -10px;
}
.country-select__wrap.be::before {
  background-position: -227px -10px;
}
.country-select__wrap.br::before {
  background-position: -351px -10px;
}
.country-select__wrap.ca::before {
  background-position: -382px -10px;
}
.country-select__wrap.ch::before {
  background-position: -475px -10px;
}
.country-select__wrap.cn::before {
  background-position: -41px -35px;
}
.country-select__wrap.de::before {
  background-position: -134px -35px;
}
.country-select__wrap.dk::before {
  background-position: -196px -35px;
}
.country-select__wrap.es::before {
  background-position: -320px -35px;
}
.country-select__wrap.eu::before {
  background-position: -351px -35px;
}
.country-select__wrap.fi::before {
  background-position: -382px -35px;
}
.country-select__wrap.fr::before {
  background-position: -413px -35px;
}
.country-select__wrap.gb::before {
  background-position: -475px -35px;
}
.country-select__wrap.hk::before {
  background-position: -41px -60px;
}
.country-select__wrap.ie::before {
  background-position: -196px -60px;
}
.country-select__wrap.it::before {
  background-position: -351px -60px;
}
.country-select__wrap.jp::before {
  background-position: -444px -60px;
}
.country-select__wrap.lu::before {
  background-position: -258px -85px;
}
.country-select__wrap.mx::before {
  background-position: -475px -85px;
}
.country-select__wrap.nl::before {
  background-position: -103px -110px;
}
.country-select__wrap.no::before {
  background-position: -134px -110px;
}
.country-select__wrap.nz::before {
  background-position: -165px -110px;
}
.country-select__wrap.pt::before {
  background-position: -413px -110px;
}
.country-select__wrap.se::before {
  background-position: -103px -135px;
}
.country-select__wrap.sg::before {
  background-position: -134px -135px;
}
.country-select__wrap.us::before {
  background-position: -475px -135px;
}
