.event-form-info-date-time {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.event-form-info-date-time .form__row {
  width: 90%;
  margin-right: 5%;
}

.event-form-info-date-time .form__row:last-child {
  width: 90%;
  margin-right: 0;
}
