.form__section-header {
  margin: 2rem 0 0 0;
  border-top: 1px solid;
  border-color: #ddd;
  padding: 1rem 1rem 0.5rem 0;
}

.form__section-header-title {
  font-size: 0.8rem;
  margin: 0 0 0.5rem 0;
  padding: 0;
  font-weight: 700;
}
