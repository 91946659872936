.timeline__text {
  display: block;
}
.timeline__text p {
  margin: 0;
  line-height: 1;
  font-size: 12px;
}
.timeline__text h1,
.timeline__text h2,
.timeline__text h3,
.timeline__text h4,
.timeline__text h5,
.timeline__text h6 {
  margin-top: 0.5rem;
}
.timeline__text h1 {
  font-size: 1.4rem;
}
.timeline__text h2 {
  font-size: 1.1rem;
}
.timeline__text h3 {
  font-size: 1rem;
}
.timeline__text h4 {
  font-size: 0.9rem;
}
.timeline__text h5 {
  font-size: 0.8rem;
}
.timeline__text h6 {
  font-size: 0.7rem;
}
.timeline__text ul,
.timeline__text ol {
  margin: 0.5rem 0;
}
.timeline__text a,
.timeline__text a:visited {
  color: #399ba2;
}
