html,
body {
  padding: 0;
  margin: 0;
}

button:first-child,
button .button:first-child {
  margin-right: 0 !important;
}
