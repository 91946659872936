.react-modal__content {
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  width: 850px;
  height: 750px;
  max-width: 100%;
  max-height: 90%;
  font-family: 'Lato', sans-serif;
}
.react-modal__content {
  outline: none;
}

.modal-content__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.react-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

@media only screen and (max-width: 1024px) {
  .react-modal__content {
    top: 52%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    width: 92%;
    height: 92%;
    max-width: 92%;
    max-height: 92%;
    font-family: 'Lato', sans-serif;
  }
}
