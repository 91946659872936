.event-email-schedule-form {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.event-email-schedule-form > .form__row {
  padding-right: 1rem;
}

.event-email-schedule-form > .form__row:last-child {
  padding-right: 0;
}

.event-email-schedule-form input[name='timePeriod'] {
  margin-top: 0.7rem;
}
