.form__row-color {
  line-height: 1.2rem;
  vertical-align: middle;
  padding: 0.5rem 0 0.5rem 0;
  display: block;
}
.form__field-color {
  display: inline-block;
  width: auto;
}
.form__label-color {
  font-size: 0.8rem;
  width: auto;
  display: inline-block;
  vertical-align: top;
  margin-right: 0.5rem;
}

.form__field-color--color {
  width: 2rem;
  height: 0.7rem;
  border-radius: 0.2rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.form__field-color--swatch {
  padding: 0.25rem;
  background: #fff;
  border-radius: 0.2rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
}
.form__field-color--popover {
  position: absolute;
  z-index: 2;
}
.form__field-color--cover {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}
