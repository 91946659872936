.lutable__header {
  text-transform: uppercase;
  display: table-row;
  position: relative;
  font-size: 0.7rem;
  font-weight: 700;
  color: #494949;
}

.lutable__header--selectable:hover {
  color: #333;
  cursor: pointer;
}

.lutable__header--borders {
  border: 1px solid #ccc;
}

@media only screen and (max-width: 768px) {
  .lutable__header {
    text-transform: uppercase;
    font-size: 0.6rem;
    line-height: 1.2;
  }
}
