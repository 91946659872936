.mailchimp-manage__events-list {
  background: #cedbe0;
  padding: 1rem;
  border-radius: 0.2rem;
  height: 300px;
  max-height: 300px;
  overflow-y: scroll;
}

.mailchimp-manage-disconnect {
  margin-left: 0;
}

@media only screen and (min-width: 900px) and (max-width: 939px) {
  .mailchimp-manage-text {
    flex: 1 0 auto;
    padding: 2rem 2rem 0 2rem;
    max-width: 45%;
  }
}

@media only screen and (max-width: 899px) {
  .mailchimp-manage {
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .mailchimp-manage-text {
    flex: 1 0 auto;
    padding: 1rem 0 0 0;
    max-width: 70%;
  }

  .mailchimp-manage-image {
    flex: 1 0 auto;
    padding: 2rem 0 0 0;
    max-width: 90%;
  }
}
