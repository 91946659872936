.checkin-counter__summary {
  display: block;
  font-size: 0.8rem;
  font-weight: bold;
  overflow: hidden;
  margin-top: 0.5rem;
}
.checkin-counter__title {
  float: left;
}
.check-counter__total {
  float: right;
}
.progress-bar {
  width: 100%;
  background: #f9f9f9;
  padding: 0.25rem;
  margin: 0.5rem 0;
  min-height: 2rem;
  line-height: 0;
  box-shadow: inset 0 0 2px #ddd;
  border-radius: 2px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.progress-bar__outer {
  display: inline-block;
  height: 1.5rem;
  overflow: hidden;
}

.progress-bar__tip {
  font-family: 'Lato', sans-serif;
}

.progress-bar__tip:first-child .progress-bar__outer {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.progress-bar__tip:last-child .progress-bar__outer {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.progress-bar__inner {
  display: inline-block;
  height: 1.5rem;
}
