.invoice-address {
  font-size: 0.9rem;
  line-height: 1.5;
  float: left;
  margin: 0;
  padding: 0;
}
.invoice-address__item {
  margin-bottom: 1.5rem;
}
.invoice-address__item--company {
  font-weight: 700;
}
