.root {
  cursor: pointer;
  height: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
}

.content {
  padding: 0.5rem;
  background-color: #ccc;
  border-radius: 0.2rem;
  font-size: 0.75rem;
  height: 100%;
  box-sizing: border-box;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.laneTicketAllocationPlacementName {
  font-weight: 700;
}

.laneTicketAllocationNotesIcon {
  padding-top: 0.25rem;
}

.laneTicketAllocationPlacementNameIcon {
  color: #333;
}
