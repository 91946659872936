.root {
  margin-top: 1rem;
}

.currency {
  font-size: 0.7rem;
  margin-right: 0.3rem;
  vertical-align: text-top;
  top: 0.2rem;
}

.price {
  font-weight: 700;
}

.amount {
  font-size: 1.6rem;
}

.divider {
  background: #ececec !important;
  font-weight: 700;
}

.smallPrint {
  text-align: center;
  margin-top: 1rem;
  font-size: 0.8rem;
}
