.lutable__row {
  position: relative;
  display: table-row;
  /* color: #666; */
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  background: #fff;
}
.lutable__row:nth-child(odd) {
  background-color: #fefefe;
}

.lutable__row--alt-color:nth-child(even) {
  background-color: #f5f5f5;
}

.lutable__row--alt-color:last-child {
  border-bottom: none;
}
.lutable__row--selectable:hover {
  /* color: #000; */
  cursor: pointer;
  background-color: #f7f8fa;
}

.lutable__row:last-child {
  border-bottom: 1px solid #eee;
}
