.paginator {
  margin: 2rem 0 1rem 0;
  width: 98%;
  padding: 0;
  font-size: 0.8rem;
  font-family: 'Lato', sans-serif;
}

.paginator__link {
  padding: 0.5rem 0.75rem;
  font-size: 0.8rem;
  margin: 0 0.25rem;
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 0.2rem;
  color: #666;
  background-color: #f9f9f9;
  font-family: 'Lato', sans-serif;
  cursor: pointer;
  box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.01),
    0 2px 5px rgba(50, 50, 93, 0.1), 0 1px 2px rgba(50, 50, 93, 0.05);
}

.paginator__link--directional {
  padding: 0.5rem 0.5rem;
}

.paginator__link--directional:first-child {
  margin: 0 0.25rem 0 0;
}

.paginator__link--directional:last-child {
  margin: 0 0 0 0.25rem;
}

.paginator__link:hover {
  color: #102052;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.02),
    0 2px 5px rgba(50, 50, 93, 0.2), 0 1px 2px rgba(50, 50, 93, 0.06);
}

.paginator__current-page {
  color: #102052;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.02),
    0 2px 5px rgba(50, 50, 93, 0.2), 0 1px 2px rgba(50, 50, 93, 0.06);
}

.paginator__current-page:hover {
  color: #102052;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.02),
    0 2px 5px rgba(50, 50, 93, 0.2), 0 1px 2px rgba(50, 50, 93, 0.06);
}

.ais-Pagination {
  margin: 2rem 0 1rem 0;
  width: 98%;
  padding: 0 1%;
  font-size: 0.8rem;
}
.ais-Pagination--noRefinement {
}
.ais-Pagination-list {
}
.ais-Pagination-list--noRefinement {
}
.ais-Pagination-item {
  padding: 0.5rem 0.75rem;
  font-size: 0.8rem;
  margin: 0 0.25rem;
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 0.2rem;
  color: #666;
  background-color: #f9f9f9;
  box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.01),
    0 2px 5px rgba(50, 50, 93, 0.1), 0 1px 2px rgba(50, 50, 93, 0.05);
}
.ais-Pagination-item--firstPage {
}
.ais-Pagination-item--lastPage {
}
.ais-Pagination-item--previousPage {
}
.ais-Pagination-item--nextPage {
}
.ais-Pagination-item--page {
}
.ais-Pagination-item--selected {
}
.ais-Pagination-item--disabled {
}
.ais-Pagination-link {
}
