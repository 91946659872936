.root {
  position: relative;
}

.content {
  position: absolute;
  right: 0;
  margin-top: 0.1rem;
  padding: 0;
  background-color: #fff;
  color: #666;
  z-index: 99;
  border: 1px solid #ddd;
  border-radius: 0.4rem;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.header {
  font-weight: 700;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;
  padding: 0.75rem 0;
  text-align: center;
}

.children {
  font-weight: 400;
  padding: 0 1rem 1rem 1rem;
}
