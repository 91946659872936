.placeholderCell {
  position: absolute;
  background-color: #ececec;
  width: 100%;
  opacity: 0.5;
  z-index: 1;
}

.notEnoughSpaceWarning {
  background-color: red;
}
