.form__row-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -5px;
  margin-right: -5px;
}
.form__row-group > .form__row {
  margin-left: 5px;
  margin-right: 5px;
}
