.root {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 1rem 0;
  margin: 1rem;
}

.row {
  display: flex;
  padding-top: 1rem;
}

.title {
  font-size: 0.9rem;
}

.name {
  flex: 1;
  font-size: 1.2rem;
}

.price {
  line-height: 2rem;
}

.controls {
  margin-left: 1rem;
}
