.dateColumnRoot {
}

.dateColumnRootHeader {
  background-color: #283544;
  color: #fff;
  border-right: 1px solid #999;
  font-size: 0.9rem;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dateKeyColumnBlocksContent {
  position: relative;
}

.dateKeyColumnBlock {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  display: flex;
  width: 100px;
  justify-content: space-between;
  height: 120px;
}

.timeslots {
  display: flex;
  flex-direction: column;
}

.hour {
  padding: 0.5rem;
  font-weight: bold;
  font-size: 0.9rem;
}

.timeslot {
  height: 30px;
  padding: 0 0.5rem 0 0.5rem;
  text-align: right;
  line-height: 30px;
  font-size: 0.8rem;
  background: #e0eaf5;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
}

.zoom {
  display: inline-block;
  font-size: 11px;
  margin-left: 0.2rem;
}
