.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0 0 0.5rem 0;
  border-bottom: 0.1rem solid #eee;
  margin-bottom: 1rem;
}
