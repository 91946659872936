.ais-SearchBox {
  display: inline-block;
  position: relative;
  width: 100%;
  white-space: nowrap;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  position: relative;
}

.ais-SearchBox-input {
  padding: 0.6rem 1rem;
  border-radius: 0.2rem;
  font-size: 0.9rem;
  background-color: #ffffff;
  border: solid 1px #ddd;
  background: transparent;
  border: none;
  display: inline-block;
  transition: box-shadow 0.4s ease, background 0.4s ease;
  border: 1px solid #d4d8e3;
  box-shadow: none;
  transition: box-shadow 0.3s, border 0.3s;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  appearance: none;
  font-family: inherit;
  margin: 0;
}

.ais-SearchBox-submit {
  left: 0.3rem;
}
.ais-SearchBox-submit,
.ais-SearchBox-reset,
.ais-SearchBox-loadingIndicator {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  top: 50%;
  right: 0.3rem;
  border: none;
  background: inherit;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ais-SearchBox-submitIcon {
  width: 12px;
  height: 12px;
}

.ais-SearchBox-submitIcon,
.ais-SearchBox-resetIcon,
.ais-SearchBox-loadingIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  fill: #9faab2;
}

.ais-SearchBox-input::-webkit-search-decoration,
.ais-SearchBox-input::-webkit-search-cancel-button,
.ais-SearchBox-input::-webkit-search-results-button,
.ais-SearchBox-input::-webkit-search-results-decoration {
  display: none;
}
.ais-SearchBox-input:hover,
.ais-SearchBox-input:active,
.ais-SearchBox-input:focus {
  box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.02),
    0 2px 5px rgba(50, 50, 93, 0.2), 0 1px 2px rgba(50, 50, 93, 0.06);
  outline: 0;
}

.ais-SearchBox-input::placeholder {
  color: #9faab2;
}

@media only screen and (max-width: 479px) {
  .ais-SearchBox {
    margin-bottom: 1rem;
  }
}
