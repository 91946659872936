.event-form-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.event-form-info {
  flex: 3 1 0;
  margin-right: 1.5rem;
}

.event-form-extras {
  flex: 1 1 0;
  background-color: #f7fafc;
  height: auto;
  border-radius: 0.2rem;
  padding: 1rem 1rem 1rem 1rem;
}

.event-form-extras-title {
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 1rem;
}

.event-form-extras label {
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.event-form__new-venue {
  display: flex;
  flex-direction: row;
}

.event-form__text-button {
  margin-left: 0.25rem;
}

@media only screen and (max-width: 900px) {
  .event-form-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .event-form-info {
    flex: 1 0 auto;
    margin-right: 0;
    width: 100%;
  }

  .event-form-extras {
    flex: 1 0 auto;
    margin-top: 1rem;
    width: 90%;
    padding: 0 5% 1rem 5%;
  }
}
