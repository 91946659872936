.info-block__image-wrap {
  margin: 1rem;
  text-align: left;
  width: 100%;
  display: inline-block;
}
.info-block__image {
  max-width: 25%;
  max-height: 25%;
}
