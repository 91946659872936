.form__field-input--select {
  font-family: 'lato', sans serif;
  font-size: 0.85rem;
  padding: 0 0.5rem;
  border-image: none;
  border-style: solid;
  border-width: 2px;
  border-color: #ddd;
  border-radius: 0.2rem;
  box-shadow: none;
  background-clip: padding-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0.7rem 0 0 0;
  background-color: #fff;
  line-height: 1.6;
  height: 2.5rem;
}

.form__field-option {
  width: 100%;
}

.form__hint {
  font-size: 0.7rem;
  margin: 0.5rem 0;
  padding: 0;
  font-weight: 400;
}

.form__hint--before {
  margin: 0 0 0.5rem 0;
}
