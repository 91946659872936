.root {
  cursor: pointer;
  background-color: #edf6f9;
  border-radius: 0.4rem;
  padding: 1.5rem;
  cursor: pointer;
  margin: 1rem;
  position: relative;
  text-align: center;
  flex: 1;
}

.selected {
  background-color: #d6e5ea;
}

.name {
  font-size: 1.2rem;
}

.description ul {
  list-style: none;
  font-size: 0.9rem;
}

.amount {
  font-size: 1.2rem;
  font-weight: bold;
}

.check {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
