.root :global(h1) {
  margin-top: 0;
}

.root :global(a) {
  margin-left: 0;
}

.root :global(p) {
  margin-bottom: 1rem;
}
