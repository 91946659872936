.receipt__header {
  border-bottom: 2px solid #eee;
}

.receipt-row--selected,
.receipt-row--selected:nth-child(odd),
.receipt-row--selected:nth-child(even) {
  background-color: #c1f1e6;
}
.receipt-row--disabled:nth-child(odd),
.receipt-row--disabled:nth-child(even),
.receipt-row--disabled {
  text-decoration: line-through;
}
.receipt-row__icon {
  padding: 0 0.2rem;
}
.receipt-row__icon--cancelled {
  color: #aa0901;
}

.receipt-row__icon--redeemed {
  color: green;
}
.receipt-row__icon--refund {
  color: #399ba2;
}
