.form-field__preview {
  max-width: 8rem;
  max-height: 8rem;
}
.form-field__placeholder {
  text-align: center;
  font-size: 0.8rem;
}
.form-field__dropzone {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  border-width: 2px;
  border-color: #ddd;
  border-radius: 0.2rem;
  border-style: dashed;
  outline: 0;
  padding: 1rem;
  cursor: pointer;
}
.form-field__dropzone-delete-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: none;
  font-weight: bold;
}
.form-field__dropzone:hover .form-field__dropzone-delete-icon {
  display: block;
}
.form-field__spinner {
}
