.invoice-header-item {
  display: block;
  line-height: 1.5;
  overflow: hidden;
  font-size: 0.9rem;
}
.invoice-header-item__title {
  font-weight: 700;
  width: 50%;
  float: left;
}
.invoice-header-item__value {
  width: 50%;
  float: right;
  text-align: right;
}
