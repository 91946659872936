.content-column {
}

.content-column__left {
  float: left;
  padding-right: 1.5rem;
  box-sizing: border-box;
}
.content-column__right {
  float: right;
  box-sizing: border-box;
}
.clear {
  clear: both;
}
