.accordion__arrow {
  display: inline-block;
  width: 24px;
  height: 12px;
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: 24px;
}

.accordion__arrow::after,
.accordion__arrow::before {
  display: block;
  position: absolute;
  top: 50%;
  width: 10px;
  height: 2px;
  background-color: currentColor;
  content: '';
}

.accordion__arrow::before {
  left: 4px;
  transform: rotate(45deg);
}

[aria-expanded='true'] .accordion__arrow::before,
[aria-selected='true'] .accordion__arrow::before {
  transform: rotate(-45deg);
}

.accordion__arrow::after {
  right: 4px;
  transform: rotate(-45deg);
}

[aria-expanded='true'] .accordion__arrow::after,
[aria-selected='true'] .accordion__arrow::after {
  transform: rotate(45deg);
}

.accordion__arrow::before,
.accordion__arrow::after {
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 479px) {
  .accordion__arrow {
    width: 24px;
    height: 12px;
    top: 30%;
    margin-top: -6px;
  }
}
