.lutable__cell {
  padding: 1rem 0.2rem;
  display: table-cell;
  position: relative;
  text-align: left;
  vertical-align: middle;
}

.lutable__cell--texttop {
  vertical-align: text-top;
}

.lutable__cell--textright {
  text-align: right;
}

.lutable__cell:first-child,
.lutable__cell:last-child {
  padding: 1rem 1rem 1rem 1rem;
}
.lutable__cell:first-child {
  /* border-left: #ddd; */
}

.lutable__cell--icon {
  vertical-align: top;
}

.lutable__cell--image {
  /*background-color: red;*/
  padding: 0.5rem 0.2rem;
  vertical-align: middle;
}

.lutable__cell--image:first-child,
.lutable__cell-image:last-child {
  padding: 0.5rem;
}

.lutable__cell-image-image {
  display: block;
}

.lutable__cell-image-image--mobile {
  display: none;
}

.lutable__cell--action {
  color: #399ba2;
}

.lutable__cell--action:hover {
  color: #333;
}

.lutable__cell--alt-color {
  border-bottom: 1px solid #eee;
}

.lutable__footer {
  padding: 0.5rem 1rem;
}

.lutable__cell-item {
  width: 100%;
  display: block;
  margin-top: 0.25rem;
}

.lutable__cell-item:first-child {
  margin-top: 0;
}

.lutable__cell-item--small {
  font-size: 0.7rem;
}

.lutable__cell-primary {
  font-weight: 700;
}

.lutable__cell-secondary {
  margin-top: 0.25rem;
}
.lutable__cell-secondary a,
.lutable__cell-secondary a:visited {
  color: #399ba2;
  font-weight: bold;
}
.lutable__cell-secondary a:hover {
  color: #3980a2;
}
.lutable__cell--borders {
  border: 1px solid #ccc;
}

.lutable__cell--center {
  text-align: center;
}

@media only screen and (max-width: 479px) {
  .lutable__cell {
    padding: 1rem 0.25rem;
  }
  .lutable__cell:first-child {
    padding: 0.5rem;
  }
  .lutable__cell:last-child {
    padding: 0.5rem;
  }
  .lutable__cell-image-image {
    display: none;
  }

  .lutable__cell-image-image--mobile {
    display: block;
  }
  .lutable__cell--center {
    vertical-align: text-top;
  }
}
