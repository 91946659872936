.report__wrapper {
  padding: 24px;
}

.report__details-wrapper {
  margin-top: 10px;
}

.report__print-header {
  display: none;
}

.report__info-range {
  font-size: 12px;
}

.report__info-raw-data {
  margin-top: 10px;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 4mm;
  }
  html,
  body {
    margin: 0;
    -webkit-print-color-adjust: exact;
  }
  .report {
    font-size: 0.8rem;
    border-collapse: collapse;
    width: 100%;
    clear: both;
    margin: 0;
    box-sizing: border-box;
    page-break-after: always;
  }
  .report__content-type {
    display: none;
  }
  .report__screen-title {
    display: none;
  }
  .report__print-header {
    width: 100%;
    display: block;
    margin: 0 0 12px 0;
  }
  .report__lu-logo {
    width: 100px;
    float: right;
  }
  .report__lu-logo img {
    width: 100%;
  }
  .report__header-heading {
    color: #999;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 1px;
  }
  .report__header-title {
    font-size: 22px;
    line-height: 40px;
    font-weight: 900;
    color: #0b1638;
  }
  .report__info-raw-data {
    display: none;
  }
}
