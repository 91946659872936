.receipt__header {
  border-bottom: 2px solid #eee;
}

.donation-row--selected,
.donation-row--selected:nth-child(odd),
.donation-row--selected:nth-child(even) {
  background-color: #c1f1e6;
}

.donation-row__icon {
  padding: 0 0.2rem;
}
.donation-row__icon--cancelled {
  color: #aa0901;
}

.donation-row__icon--redeemed {
  color: #399ba2;
}
