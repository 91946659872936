.report__print-table-title {
  display: none;
}

.report-table-container {
  margin-top: 24px;
}

table.report-table {
  font-size: 0.7rem;
  text-align: left;
  border-collapse: collapse;
  margin-top: 0.25rem;
  width: 100%;
  page-break-after: auto;
}
table.report-table thead tr th,
table.report-table tbody tr th {
  background-color: #efefef;
  border: 1px solid #ccc;
  font-size: 0.7rem;
  padding: 0.25rem;
  line-height: 2;
  text-align: center;
}

table.report-table .report-table__total {
  text-align: left;
}
table.report-table .report-table__row-label {
  text-align: left;
}

table.report-table tbody tr td {
  color: #0b2242;
  padding: 0 0.25rem;
  background-color: #fff;
  border: 1px solid #c8d4e3;
  vertical-align: middle;
  text-align: center;
  line-height: 2;
  page-break-inside: avoid;
  page-break-after: auto;
}

table.report-table tbody tr td.report-table__row-label {
  vertical-align: top;
  background-color: #efefef;
  border: 1px solid #ccc;
  font-size: 0.7rem;
  padding: 0.25rem;
  line-height: 1.5;
  text-align: left;
  overflow: hidden;
}

table.report-table .report-table__grand-total,
table.report-table .report-table__total {
  font-weight: 700;
  border-top: 2px solid #8795a9;
  border-bottom: 2px solid #8795a9;
  background: #dbe4f0;
}

.report-table__cells {
  border: 1px solid #a2b1c6;
  background: #f2f5fa;
  padding: 0.2rem;
  min-height: 15px;
}

/* mobile scrolling */

@media only screen and (max-width: 768px) {
  table.report-table {
    width: 250%;
    margin-top: 0;
    position: absolute;
  }
  .report-table-container {
    overflow: scroll;
    border: 1px solid #ddd;
  }
}

/* print styles */

@media print {
  .report__screen-table-title {
    display: none;
  }
  .report__print-table-title {
    display: block;
    margin: 0px;
    font-size: 16px;
    max-width: 624px;
    font-weight: 900;
    color: #0b1638;
  }
  .report__csv-link-button {
    display: none;
  }
}
