.donation-options {
  margin: 0.5rem 0;
  width: 100%;
}
.donation-option {
  width: 100%;
  display: block;
}

.form__row--dontation {
  margin: 0;
  width: 40%;
  display: inline-block;
  margin: 0;
}
.form__row--dontation input {
  margin: 0;
}
.donation-option__button {
  float: right;
  margin-top: -2rem;
}
.form__error--donation {
  color: #ff3323;
  font-size: 0.7rem;
  display: block;
  margin-top: 0.5rem;
}
