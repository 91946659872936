.form__section {
  /*border: 1px solid #ddd;*/
  margin: 0 0 1rem 0;
  overflow: visible;
  padding: 1rem;
  border-radius: 0.2rem;
  background-color: #f7fafc;
  /*box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);*/
}

.form__section-title {
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 1rem;
}
