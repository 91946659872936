.form-container {
  width: 100%;
  margin: 0;
  overflow: visible;
  padding: 0;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-container--sectional {
  width: 100%;
  margin: 0;
  border: none;
  overflow: visible;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}

.form-container--hasborder {
  width: 100%;
  margin: 0;
  border: 1px solid #eee;
  overflow: visible;
  padding: 0;
  border-radius: 6px;
  box-shadow: 0 15px 35px 0 rgb(60 66 87 / 8%), 0 5px 15px 0 rgb(0 0 0 / 12%);
}

.form {
  width: 100%;
}

.form__header {
  padding: 1rem 1.5rem;
  background: #f7fafc;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.form__header--sectional {
  background-color: #fff;
  border-bottom: none;
  border-color: none;
  padding: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.form__header--hasborder {
  background-color: #fff;
  padding: 0.5rem 1.5rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.form__header--heavypadding {
  padding: 1.5rem 2.5rem 0.5rem 2.5rem;
}

.form__header--headercentered {
  text-align: center;
}

.form__header-title {
  font-size: 1.2rem;
  margin: 0;
  padding: 0.5rem 0;
  font-weight: 900;
  color: #0b1638;
}

.form__header-notes,
.form__form-error {
  font-size: 0.85rem;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  color: #0b1638;
}
.form__form-error {
  color: #ff3323;
}

.form__form-area {
  padding: 1rem 1.5rem;
  background: #f7fafc;
}
.form__form-area--sectional {
  background-color: #fff;
  padding: 0;
}

.form__form-area--hasborder {
  padding: 1.5rem;
  background-color: #fff;
}

.form__form-area--heavypadding {
  padding: 1rem 2.5rem;
  background-color: #fff;
}

.form__footer {
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  background: #f7fafc;
  clear: both;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.form__footer--sectional {
  padding: 0.75rem 0;
  clear: both;
  background-color: #fff;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form__footer--hasborder {
  padding: 0.75rem 1.5rem 1.5rem 1.5rem;
  clear: both;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
}

.form__footer--heavypadding {
  padding: 0.75rem 2.5rem 2.5rem 2.5rem;
  background-color: #fff;
}

.form__submit {
  text-align: left;
}

.form__submit--full {
  width: 100%;
}
