.alert-modal {
  background-color: #fff;
  margin: 0 auto;
  width: 300px;
  padding: 1rem;
  border: 1px solid #ddd;
  overflow: visible;
  border-radius: 0.4rem;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.alert__title {
  background-color: #fff;
  border-bottom: 1px solid;
  border-color: #f1f1f1;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
}

.alert__description {
  margin-bottom: 1rem;
  font-size: 0.8rem;
  line-height: 1.5;
  text-align: center;
}
.alert__actions {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}
.alert__button {
  flex: 0 1 45%;
}
