.Toastify__toast--success {
  background: #399ba2 !important;
}

.Toastify__toast--warning {
  background: #d1a326 !important;
}
.Toastify__toast--error {
  background: #ff2e4a !important;
}

.Toastify__toast--default {
  background: #399ba2 !important;
  color: #fff !important;
}

.Toastify__toast-body {
  font-family: 'Lato', sans-serif !important;
  font-size: 0.9rem;
  font-weight: 900;
}

.Toastify__toast {
  border-radius: 3px !important;
}

.loading-bar {
  background-color: #399ba2;
  position: absolute;
  height: 3px;
}

@media print {
  .loading-bar {
    display: none;
  }
}
