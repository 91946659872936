.DayPickerInput {
  display: block;
}
.form__row--inline .DayPickerInput {
  margin-right: 0.75rem;
}
.DayPickerInput input {
  font-family: inherit;
  font-size: 0.85rem;
  padding: 0.4rem 0.75rem;
  line-height: 1.5;
  border-image: none;
  border-style: solid;
  border-width: 2px;
  border-color: #ddd;
  border-radius: 0.2rem;
  background-clip: padding-box;
  display: block;
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
}

.DayPickerInput input:focus {
  outline: 0;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-color: #fff;
}
.DayPickerInput input::placeholder {
  color: #999;
}
.DayPicker {
  font-size: 0.8rem;
}
