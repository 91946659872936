/* DayPicker styles */
.date-range {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: relative;
}
.day-picker {
  display: inline-block;
  font-size: 0.8rem;
  flex: 2 1 150px;
}
.day-picker input {
  font-family: inherit;
  font-size: 0.85rem;
  padding: 0.4rem 0.75rem;
  line-height: 1.5;
  border-image: none;
  border-style: solid;
  border-width: 2px;
  border-color: #ddd;
  border-radius: 0.2rem;
  background-clip: padding-box;
  display: block;
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
}

.day-picker input:focus {
  outline: 0;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-color: #fff;
}
.day-picker input::placeholder {
  color: #999;
}

.date-range__spacer {
  padding: 0 0.5rem;
  flex: 1 2 auto;
  text-align: center;
}

.day-picker__overlay {
  right: 0;
  z-index: 1;
  position: absolute;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  min-width: 550px;
}

.day-picker__overlay-wrap {
}

.day-picker__actions {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding: 0 1rem 1rem 0;
}
.date-range .DayPicker-wrapper {
  padding-bottom: 0;
}
.date-range .DayPicker {
  width: 100%;
  font-size: 0.7rem;
}
.date-range .DayPicker-Caption > div {
  font-size: 0.9rem;
}
.date-range .DayPicker-Month {
  margin: 1rem;
}
.date-range .DayPicker-NavButton {
  top: 1rem;
  right: 0.5rem;
}
.date-range
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #dee7f4 !important;
  color: #4a90e2;
}
.date-range .DayPicker-Day {
  border-radius: 0 !important;
}
.date-range .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.date-range .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

@media screen and (max-width: 920px) {
  .day-picker__overlay {
    min-width: 100%;
    z-index: 10;
  }
}
