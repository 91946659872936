.lutable {
  display: table;
  width: 100%;
  font-size: 0.8rem;
  position: relative;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;
  background: #e9eff5;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

.lutable-title {
  font-size: 0.75rem;
  margin: 0 0 0.75rem 0;
  padding: 0;
  font-weight: 700;
}

.lutable--nested {
  width: 100%;
  margin: 1rem 0 0.7rem 0;
  border-radius: 0.2rem;
  background-color: #fff;
}

.lutable--borders {
  /* margin: 1rem 2%; */
}

@media only screen and (max-width: 768px) {
  .lutable {
    font-size: 0.7rem;
  }
  .lutable--mobile-wide {
    width: 250%;
  }
  .lutable-container {
    overflow: scroll;
    border: 1px solid #ddd;
  }
  .lutable-container--container-border {
    border: 1px solid #f1f1f1;
    overflow: scroll;
    padding: 2%;
    width: 96%;
    margin: 0;
  }
  .lutable-container--mobile-wide {
    overflow: scroll;
    border-right: 2px solid #ddd;
  }
}
