.laneCellDropTarget {
  position: absolute;
  background-color: #fff;
  width: 100%;
  opacity: 0.6;
  z-index: 2;
  cursor: pointer;
}

.notEnoughSpaceWarning {
  background-color: red;
}
