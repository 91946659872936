.invoice-items-table {
  font-size: 0.8rem;
  border-collapse: collapse;
  width: 100%;
  clear: both;
  page-break-after: auto;
}
.invoice-items-table__header {
  font-weight: 400;
  color: #399ba2;
  border-bottom: 1px solid #399ba2;
}

.invoice-items-table__row {
  page-break-inside: avoid;
  page-break-after: auto;
}
.invoice-items-table__row:nth-child(odd) {
  background: #f3f9fb;
}

.invoice-items-table__row-summary--subtotal {
  border-top: 1px solid #399ba2;
}

.invoice-items-table__cell {
  text-align: right;
  vertical-align: middle;
  padding: 0.5rem;
  page-break-inside: avoid;
  page-break-after: auto;
}

.invoice-items-table__cell--subtotal {
  background: #f3f9fb;
}
.invoice-items-table__cell--total {
  border-top: 1px solid #399ba2;
  background: #d5dae0;
  font-weight: 700;
}
.invoice-items-table__cell--text {
  text-align: left;
}
