.laneColumnRoot {
  height: 100%;
}

.laneColumnContent {
  position: relative;
  border-right: 1px solid #ccc;
}

.laneColumnHeader {
  background-color: #283544;
  color: #fff;
  padding: 0 0.75rem;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 0.9rem;
  border-right: 1px solid #999;
  align-items: center;
  height: 48px;
}

.laneColumnName {
  max-width: 150px;
  width: 150px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.laneColumnCapacity {
  background: #4274ae;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  cursor: default;
}

.laneColumnBlock {
  height: 120px;
  border-bottom: 1px solid #ccc;
  background: #ededed;
  opacity: 0.5;
}

.laneColumnDisabled {
  background-color: #ffc7c7;
}

.laneColumnLocked {
  background-color: #999;
}
