.page {
  page-break-after: always;
}

.header {
  width: 100%;
  display: block;
  height: 4rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.header__title {
  font-size: 1.7rem;
  color: #999;
  text-transform: uppercase;
  font-weight: bold;
  float: right;
}
.header__logo {
  float: left;
}
.header__logo img {
  height: 100%;
}

.invoice-details {
  width: 50%;
  float: right;
}
.invoice-table {
  display: table;
  width: 100%;
  font-size: 0.8rem;
  position: relative;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;
  clear: both;
}
.invoice-table__header {
  font-weight: 400;
  color: #399ba2;
  border-bottom: 1px solid #399ba2;
}

.invoice-table__row {
  position: relative;
  display: table-row;
  color: #666;
}

.invoice-table__row:nth-child(odd) {
  background: #f3f9fb;
}

.invoice-table__cell {
  padding: 1rem 0.2rem;
  display: table-cell;
  position: relative;
  text-align: left;
  vertical-align: text-top;
}
