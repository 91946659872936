.text-editor {
  background-color: white;
  overflow: hidden;
  overflow-x: auto;
  min-height: 5rem;
}
.text-editor h1 {
  font-size: 1.5rem;
  margin-top: 1rem;
}
.text-editor h2 {
  font-size: 1.25rem;
  margin-top: 0.5rem;
}
.text-editor h3 {
  font-size: 1.1rem;
  margin-top: 0.25rem;
}

.text-editor h1,
.text-editor h2,
.text-editor h3 {
  font-weight: 700;
  line-height: 1;
}
.text-editor p,
.text-editor pre,
.text-editor blockquote,
.text-editor ul,
.text-editor ol {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.text-editor a {
  text-decoration: underline;
}
.text-editor hr {
  border: 1px solid;
  margin-bottom: 16px;
}
.text-editor li > p {
  margin: 0;
}
.text-editor ul,
.text-editor ol {
  padding-left: 2rem;
}
.text-editor pre {
  white-space: pre-wrap;
}
.text-editor code {
  padding: 0 0.1rem;
  font-size: 85%;
}
.text-editor pre > code {
  display: block;
  width: 100%;
  overflow-y: auto;
  background-color: #000;
  color: #ccc;
  border-radius: 0.1rem;
  padding: 0.5rem;
}
.text-editor blockquote {
  padding-left: 1rem;
  border-left: 0.1rem solid black;
  margin-left: 0;
  margin-right: 0;
}
.text-editor table {
  border-collapse: collapse;
}
.text-editor td,
.text-editor th {
  border: 0.1rem solid black;
  padding: 0.25rem;
  text-align: left;
}
