.section-title {
  font-family: 'Lato', sans-serif;
  margin-top: 0;
  display: inline-block;
  width: 70%;
  margin: 1rem 0 0.5rem 0;
  font-weight: 700;
  font-size: 1rem;
}

.section-title:first-child {
  margin-top: 0;
}
