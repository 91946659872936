.order-export-form__section-header {
  margin: 0.5rem 0 0 0;
  border-top: 1px solid;
  border-color: #ddd;
  padding: 0.5rem 0 0 0;
}

.order-export-form__section-header-title {
  font-size: 0.8rem;
  margin: 0;
  padding: 0.5rem 0;
}
