.ticket-pricing-summary {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin: 1rem 0 0 0;
}

.ticket-pricing-summary-column {
  flex: 1 0 auto;
  max-width: 50%;
  width: 50%;
  border-right: 1px solid #aaa;
}

.ticket-pricing-summary-title {
  font-size: 0.85rem;
  font-weight: 700;
  padding: 0.5rem 0;
}

.ticket-pricing-summary-subtitle {
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.5rem 0;
  text-transform: uppercase;
}

.ticket-pricing-summary-column:last-child {
  border-right: none;
}

@media only screen and (max-width: 768px) {
  .ticket-pricing-summary {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    margin: 1rem 0 0 0;
  }

  .ticket-pricing-summary-column {
    flex: 1 0 auto;
    max-width: 100%;
    width: 100%;
    border-right: none;
    margin-bottom: 20px;
  }
}
