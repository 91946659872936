.toolbar-button {
  border: none;
  background-color: transparent;
  border-radius: 0.2rem;
  font-size: 0.8rem;
  margin: 0;
  font-weight: bold;
  cursor: pointer;
  color: #aaa;
  outline: 0;
}

.toolbar-button:hover {
  color: #999;
}

.toolbar-button.toolbar-button--active {
  color: #000;
  background: #ccc;
}
