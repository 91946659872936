textarea {
  resize: none;
}

/* Form Row Stylyes */
.form__row {
  margin-bottom: 1rem;
  flex: auto;
}
.form__row--inline {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.form__row--check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form__row--check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

/* For Field Stylyes */
.form__field-input {
  font-family: inherit;
  font-size: 0.9rem;
  padding: 0.4rem 0.75rem;
  line-height: 1.5;
  border-image: none;
  border-style: solid;
  border-width: 2px;
  border-color: #ddd;
  border-radius: 0.2rem;
  background-clip: padding-box;
  display: block;
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
}

.form__field-input:focus {
  outline: 0;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-color: #fff;
}
.form__field-input::placeholder {
  color: #999;
}

.form__field-input--inline {
  flex: 0 0 20%;
  margin-right: 0.75rem;
}
.form__field-input--check {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form__field-input--check-inline {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.form__label-switch {
  display: flex;
  justify-content: space-between;
}

/* Form Label Stylyes */
.form__label {
  font-size: 0.9rem;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.form__label--inline {
  margin-bottom: 0;
  margin-right: 0.75rem;
  flex: 0 1 auto;
}
.form__label--check-inline {
  margin-bottom: 0;
  margin-right: 0.75rem;
}

/* error Styles */

.form__error {
  color: #ff3323;
  font-size: 0.7rem;
  display: block;
  margin-top: 0.25rem;
  width: 100%;
}

.form__error--inline {
  color: #ff3323;
  font-size: 0.7rem;
}

.form__field-input--error {
  border-color: #ff3323 !important;
}

.form__hint {
  font-size: 0.7rem;
  margin: 0.5rem 0;
  padding: 0;
  font-weight: 400;
}

.form__hint--before {
  margin: 0 0 0.5rem 0;
}

.form__hint--after {
  margin: 0.5rem 0;
}
