.root {
  display: flex;
  margin-top: 1rem;
}

.left {
  flex: 1;
  flex-basis: 50%;
  height: 800px;
}

.right {
  flex: 1;
  flex-basis: 50%;
  height: 800px;
}

@media only screen and (max-width: 768px) {
  .root {
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
  }
  .left {
    flex: 1 0 auto;
    flex-basis: 100%;
    height: 50px;
  }

  .right {
    flex: 1 0 auto;
    flex-basis: 100%;
    height: 800px;
  }
}
