.button {
  padding: 0.6rem 1rem;
  border-radius: 0.2rem;
  cursor: pointer;
  font-size: 13px;
  font-weight: 900;
  text-decoration: none;
  white-space: nowrap;
  word-break: keep-all;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  /* box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.01),
    0 2px 5px rgba(50, 50, 93, 0.1), 0 1px 2px rgba(50, 50, 93, 0.05); */
  margin-left: 0.8rem;
  outline: 0;
  border: 1px solid #eee;
  color: inherit;
  background-color: white;
}

.button:first-child {
  margin-left: 0;
}

.button--nomargin {
  margin-left: 0;
}

.button--primary {
  background-color: #399ba2;
  color: #f1f1f1;
  border: 1px solid #399ba2;
}

.button--primary:visited {
  color: #f1f1f1;
}

.button--primary:hover {
  background-color: #32898f;
  color: #fff;
  border: 1px solid #32898f;
}

.button--danger,
.button--danger:visited {
  background-color: #aa0901;
  color: #fff;
  border: 1px solid #aa0901;
}

.button--danger:hover {
  background-color: #aa0901;
  color: #fff;
}

.button--secondary {
  background-color: #dfe6f5;
  color: #0b1638;
  border: 1px solid #dfe6f5;
}

.button--secondary:hover {
  background-color: #cfd5e3;
  color: #0b1638;
  border: 1px solid #cfd5e3;
}

.button--tertiary {
  background-color: #d7deeb;
  color: #666;
  border: 1px solid #d7deeb;
}
.button--tertiary:hover {
  background-color: #c4cad6;
  color: #666;
  border: 1px solid #c4cad6;
}

.button--quaternary {
  background-color: #c1f1e6;
  color: #666;
  border: 1px solid #c1f1e6;
}
.button--quaternary:hover {
  color: #666;
  background-color: #b0dcd2;
  border: 1px solid #b0dcd2;
}

.button--mailchimp {
  background-color: #ffe01b;
  color: #241c15;
  border: 1px solid #ffe01b;
  margin-left: 0;
}
.button--mailchimp:hover {
  color: #241c15;
  background-color: #e0bf29;
  border: 1px solid #e0bf29;
}

.button:first-child {
  margin-left: 0;
}

.button:disabled {
  background-color: #f1f1f1;
  color: #ddd;
  box-shadow: none;
  border-color: #f1f1f1;
}

.button__icon {
  margin-right: 0.2rem;
}
.button__icon--no-label {
  margin-right: 0;
}

.button--slim {
  padding: 0.2rem 1%;
}

.button--content {
  background-color: #f9f9f9;
  box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.03),
    0 2px 5px rgba(50, 50, 93, 0.1), 0 1px 2px rgba(50, 50, 93, 0.08);
}

.button--content:hover {
  background-color: #f7f7f7;
  box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.04),
    0 2px 5px rgba(50, 50, 93, 0.2), 0 1px 2px rgba(50, 50, 93, 0.09);
}
.button-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-spinner > div {
  width: 1rem;
  height: 1rem;
}

.button--small {
}

.button--large {
  font-size: 1.2rem;
}

.button--full {
  width: 100%;
}

@media only screen and (max-width: 739px) {
  .button--small {
    padding: 0.2rem 1%;
    margin-left: 0;
  }
  .button--cell {
    width: 100%;
    text-align: center;
    margin-bottom: 0.5rem;
  }
}

@media print {
  .button {
    display: none;
  }
}
